// Optimized Routes
import { default as index7WrWGfkMu7Meta } from "/builds/nextory/web/nx-web/pages/index.vue?macro=true";
import { default as _91slug_93qFhfWtLf7vMeta } from "/builds/nextory/web/nx-web/pages/[iso]/partner-collaboration/[slug].vue?macro=true";
import { default as _91slug_93gzp8DfQP2YMeta } from "/builds/nextory/web/nx-web/pages/[slug].vue?macro=true";
import { default as aboutWOUrUCMXCzMeta } from "/builds/nextory/web/nx-web/pages/about.vue?macro=true";
import { default as accessibilitycaCgn5WeXaMeta } from "/builds/nextory/web/nx-web/pages/accessibility.vue?macro=true";
import { default as updateuokNvCwGoYMeta } from "/builds/nextory/web/nx-web/pages/account/subscription/update.vue?macro=true";
import { default as loginoa8f2zYG1RMeta } from "/builds/nextory/web/nx-web/pages/action/login.vue?macro=true";
import { default as audiobooksxcKKmaIkVeMeta } from "/builds/nextory/web/nx-web/pages/audiobooks.vue?macro=true";
import { default as _91slug_93Ku2wEZ28zzMeta } from "/builds/nextory/web/nx-web/pages/author/[slug].vue?macro=true";
import { default as _91slug_932nzjUIpa5QMeta } from "/builds/nextory/web/nx-web/pages/book/[slug].vue?macro=true";
import { default as booksoKUYqkCBu9Meta } from "/builds/nextory/web/nx-web/pages/categories/books.vue?macro=true";
import { default as magazinesz3Zn7ntxnVMeta } from "/builds/nextory/web/nx-web/pages/categories/magazines.vue?macro=true";
import { default as _91slug_93oGJB6cAXioMeta } from "/builds/nextory/web/nx-web/pages/category/books/[slug].vue?macro=true";
import { default as indexnFmb7boiCSMeta } from "/builds/nextory/web/nx-web/pages/category/index.vue?macro=true";
import { default as _91slug_93Y0xARaMsNNMeta } from "/builds/nextory/web/nx-web/pages/category/magazines/[slug].vue?macro=true";
import { default as _91slug_93Lz7BXn14UoMeta } from "/builds/nextory/web/nx-web/pages/collab/[type]/[slug].vue?macro=true";
import { default as _91slug_93tcPJWqzaSsMeta } from "/builds/nextory/web/nx-web/pages/collab/contact/[slug].vue?macro=true";
import { default as _91slug_93IGKbJJa7AqMeta } from "/builds/nextory/web/nx-web/pages/content/[slug].vue?macro=true";
import { default as download_45appsMVg2VtAgY5Meta } from "/builds/nextory/web/nx-web/pages/download-apps.vue?macro=true";
import { default as e_45booksJ2VoX65FsiMeta } from "/builds/nextory/web/nx-web/pages/e-books.vue?macro=true";
import { default as errorz1q9oBZV92Meta } from "/builds/nextory/web/nx-web/pages/error.vue?macro=true";
import { default as for_45kidsKFDo2BtVhpMeta } from "/builds/nextory/web/nx-web/pages/for-kids.vue?macro=true";
import { default as health_45checkDFwoyjSBJkMeta } from "/builds/nextory/web/nx-web/pages/health-check.vue?macro=true";
import { default as imprint3ET3BXRLRAMeta } from "/builds/nextory/web/nx-web/pages/imprint.vue?macro=true";
import { default as launchSKIUJb4oT4Meta } from "/builds/nextory/web/nx-web/pages/in-app/launch.vue?macro=true";
import { default as nextory_45appC3OqzSOFn9Meta } from "/builds/nextory/web/nx-web/pages/launch/nextory-app.vue?macro=true";
import { default as magazineshZvXPiABx6Meta } from "/builds/nextory/web/nx-web/pages/magazines.vue?macro=true";
import { default as _91id_93eUne15OPTkMeta } from "/builds/nextory/web/nx-web/pages/migration/book/[id].vue?macro=true";
import { default as _91slug_93KFsGPwIuhTMeta } from "/builds/nextory/web/nx-web/pages/migration/category/[slug].vue?macro=true";
import { default as _91slug_93zyfmyR3jFDMeta } from "/builds/nextory/web/nx-web/pages/narrator/[slug].vue?macro=true";
import { default as privacy_45policyyVkw9BlJjHMeta } from "/builds/nextory/web/nx-web/pages/privacy-policy.vue?macro=true";
import { default as disclaimergov7o876olMeta } from "/builds/nextory/web/nx-web/pages/profile/cancel/disclaimer.vue?macro=true";
import { default as downgrade_45successqCzDhRONNMMeta } from "/builds/nextory/web/nx-web/pages/profile/cancel/downgrade-success.vue?macro=true";
import { default as extend_45trial_45successR4tjMXPf5tMeta } from "/builds/nextory/web/nx-web/pages/profile/cancel/extend-trial-success.vue?macro=true";
import { default as offerwlUkSoVa0qMeta } from "/builds/nextory/web/nx-web/pages/profile/cancel/offer.vue?macro=true";
import { default as reasonsJrnIipy4LSMeta } from "/builds/nextory/web/nx-web/pages/profile/cancel/reasons.vue?macro=true";
import { default as revert_45canceleDbs0tLpt6Meta } from "/builds/nextory/web/nx-web/pages/profile/cancel/revert-cancel.vue?macro=true";
import { default as stay_45another_45month_45successovk3vltEDWMeta } from "/builds/nextory/web/nx-web/pages/profile/cancel/stay-another-month-success.vue?macro=true";
import { default as successl36nLmp96nMeta } from "/builds/nextory/web/nx-web/pages/profile/cancel/success.vue?macro=true";
import { default as change_45emailYYMH2WsmZOMeta } from "/builds/nextory/web/nx-web/pages/profile/change-email.vue?macro=true";
import { default as change_45passwordDaQrFQuteQMeta } from "/builds/nextory/web/nx-web/pages/profile/change-password.vue?macro=true";
import { default as indexC9R9enrEPXMeta } from "/builds/nextory/web/nx-web/pages/profile/index.vue?macro=true";
import { default as login_45partnero7glSHvBJJMeta } from "/builds/nextory/web/nx-web/pages/profile/login-partner.vue?macro=true";
import { default as loginMRPBdckEnwMeta } from "/builds/nextory/web/nx-web/pages/profile/login.vue?macro=true";
import { default as order_45historyTbLoZx8QkaMeta } from "/builds/nextory/web/nx-web/pages/profile/order-history.vue?macro=true";
import { default as profileJDAo1KQArEMeta } from "/builds/nextory/web/nx-web/pages/profile/profile.vue?macro=true";
import { default as freed3gVMUq31pMeta } from "/builds/nextory/web/nx-web/pages/profile/providers/free.vue?macro=true";
import { default as orange_45tntYMqi9RP3aMeta } from "/builds/nextory/web/nx-web/pages/profile/providers/orange-tn.vue?macro=true";
import { default as orangenHerSLotNyMeta } from "/builds/nextory/web/nx-web/pages/profile/providers/orange.vue?macro=true";
import { default as quick_45loginEsUWiediYEMeta } from "/builds/nextory/web/nx-web/pages/profile/quick-login.vue?macro=true";
import { default as read_45and_45listen_45againYaY3SmgNUEMeta } from "/builds/nextory/web/nx-web/pages/profile/read-and-listen-again.vue?macro=true";
import { default as receipt6cBpO9E7geMeta } from "/builds/nextory/web/nx-web/pages/profile/receipt.vue?macro=true";
import { default as registrationiNgHk5tf6MMeta } from "/builds/nextory/web/nx-web/pages/profile/registration.vue?macro=true";
import { default as index3QLciIMuuWMeta } from "/builds/nextory/web/nx-web/pages/profile/reset-password/index.vue?macro=true";
import { default as success718W2iXoMZMeta } from "/builds/nextory/web/nx-web/pages/profile/reset-password/success.vue?macro=true";
import { default as back_45to_45appjwOVShiNQPMeta } from "/builds/nextory/web/nx-web/pages/register/back-to-app.vue?macro=true";
import { default as _91slug_93m6PSmlgiyEMeta } from "/builds/nextory/web/nx-web/pages/register/campaign/[slug].vue?macro=true";
import { default as activateaoKt9Ry0I6Meta } from "/builds/nextory/web/nx-web/pages/register/campaign/activate.vue?macro=true";
import { default as validate8oL0ldoaFgMeta } from "/builds/nextory/web/nx-web/pages/register/campaign/validate.vue?macro=true";
import { default as change_45payment_45success00A2RAlV7AMeta } from "/builds/nextory/web/nx-web/pages/register/change-payment-success.vue?macro=true";
import { default as change_45successhAWvVLoTrrMeta } from "/builds/nextory/web/nx-web/pages/register/change-success.vue?macro=true";
import { default as challengeNMSnvC27EYMeta } from "/builds/nextory/web/nx-web/pages/register/dimoco/challenge.vue?macro=true";
import { default as indexz4OV1hiFNwMeta } from "/builds/nextory/web/nx-web/pages/register/dimoco/index.vue?macro=true";
import { default as get_45startedGjWzEuTwCHMeta } from "/builds/nextory/web/nx-web/pages/register/get-started.vue?macro=true";
import { default as activateEUdXAd0ylrMeta } from "/builds/nextory/web/nx-web/pages/register/giftcard/activate.vue?macro=true";
import { default as infoaim6VTqDxtMeta } from "/builds/nextory/web/nx-web/pages/register/giftcard/info.vue?macro=true";
import { default as successguBUA10PYoMeta } from "/builds/nextory/web/nx-web/pages/register/giftcard/success.vue?macro=true";
import { default as ideal4Vz2uCsdnwMeta } from "/builds/nextory/web/nx-web/pages/register/ideal.vue?macro=true";
import { default as indexp9otkVab4UMeta } from "/builds/nextory/web/nx-web/pages/register/index.vue?macro=true";
import { default as indexyGu9z3LRTvMeta } from "/builds/nextory/web/nx-web/pages/register/partner/[channel]/index.vue?macro=true";
import { default as link3d8OGI3nIwMeta } from "/builds/nextory/web/nx-web/pages/register/partner/[channel]/link.vue?macro=true";
import { default as thank_45youWMAoyyDIZ4Meta } from "/builds/nextory/web/nx-web/pages/register/partner/thank-you.vue?macro=true";
import { default as payment_45modeLmQkHb0ioMMeta } from "/builds/nextory/web/nx-web/pages/register/payment-mode.vue?macro=true";
import { default as payment_45successwTYx87IGOsMeta } from "/builds/nextory/web/nx-web/pages/register/payment-success.vue?macro=true";
import { default as paypaldk1oXXj7GeMeta } from "/builds/nextory/web/nx-web/pages/register/paypal.vue?macro=true";
import { default as planformgauQ43SDhBMeta } from "/builds/nextory/web/nx-web/pages/register/planform.vue?macro=true";
import { default as planinfo6G7vtz9vMaMeta } from "/builds/nextory/web/nx-web/pages/register/planinfo.vue?macro=true";
import { default as sofort6UNv22Sd16Meta } from "/builds/nextory/web/nx-web/pages/register/sofort.vue?macro=true";
import { default as subscription_45pickerGtsn4dDqsWMeta } from "/builds/nextory/web/nx-web/pages/register/subscription-picker.vue?macro=true";
import { default as trustlyHU2tHpkS5AMeta } from "/builds/nextory/web/nx-web/pages/register/trustly.vue?macro=true";
import { default as welcome8kx1qbgZkRMeta } from "/builds/nextory/web/nx-web/pages/register/welcome.vue?macro=true";
import { default as searchsRRuYhowl1Meta } from "/builds/nextory/web/nx-web/pages/search.vue?macro=true";
import { default as _91slug_93qtaHeRYL34Meta } from "/builds/nextory/web/nx-web/pages/series/[slug].vue?macro=true";
import { default as consumed_45hoursK4LzDENdyyMeta } from "/builds/nextory/web/nx-web/pages/subscriptions_faq/consumed-hours.vue?macro=true";
import { default as logged_45outuZwVoNr04eMeta } from "/builds/nextory/web/nx-web/pages/subscriptions_faq/logged-out.vue?macro=true";
import { default as ab_45test_45sample2wAEUy44HWMeta } from "/builds/nextory/web/nx-web/pages/test/ab-test-sample.vue?macro=true";
import { default as user_45termsa81g33M8rRMeta } from "/builds/nextory/web/nx-web/pages/user-terms.vue?macro=true";
import { default as component_45stubyaJx2e3ivpMeta } from "/builds/nextory/web/nx-web/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
const locales = ["se","se-en","fr","fr-en","be-fr","be-en","nl","nl-en","no","no-en","es","es-en","dk","dk-en","fi","fi-en","ch","ch-en","at","at-en","de","de-en"];
function genI18nRoute(basePath) {
  return locales.map(locale => `/${locale}${basePath}`);
}
function genI18nName(baseName) {
  return locales.map(locale => `${baseName}___${locale}`);
}
const packedRoutes = [
[
  () => import("/builds/nextory/web/nx-web/pages/index.vue"),
  ["/", "/se", "/se-en", "/fr", "/fr-en", "/be-fr", "/be-en", "/nl", "/nl-en", "/no", "/no-en", "/es", "/es-en", "/dk", "/dk-en", "/fi", "/fi-en", "/ch", "/ch-en", "/at", "/at-en", "/de", "/de-en"],
  ["index", "index___se", "index___se-en", "index___fr", "index___fr-en", "index___be-fr", "index___be-en", "index___nl", "index___nl-en", "index___no", "index___no-en", "index___es", "index___es-en", "index___dk", "index___dk-en", "index___fi", "index___fi-en", "index___ch", "index___ch-en", "index___at", "index___at-en", "index___de", "index___de-en"],
  index7WrWGfkMu7Meta
],
[
  () => import("/builds/nextory/web/nx-web/pages/[iso]/partner-collaboration/[slug].vue"),
  genI18nRoute("/:iso()/partner-collaboration/:slug()"),
  genI18nName("iso-partner-collaboration-slug"),
  _91slug_93qFhfWtLf7vMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/[slug].vue"),
  genI18nRoute("/:slug()"),
  genI18nName("slug"),
  _91slug_93gzp8DfQP2YMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/about.vue"),
  genI18nRoute("/about"),
  genI18nName("about"),
  aboutWOUrUCMXCzMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/accessibility.vue"),
  genI18nRoute("/accessibility"),
  genI18nName("accessibility"),
  accessibilitycaCgn5WeXaMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/account/subscription/update.vue"),
  genI18nRoute("/account/subscription/update"),
  genI18nName("account-subscription-update"),
  updateuokNvCwGoYMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/action/login.vue"),
  genI18nRoute("/action/login"),
  genI18nName("action-login"),
  loginoa8f2zYG1RMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/audiobooks.vue"),
  genI18nRoute("/audiobooks"),
  genI18nName("audiobooks"),
  audiobooksxcKKmaIkVeMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/author/[slug].vue"),
  genI18nRoute("/author/:slug()"),
  genI18nName("author-slug"),
  _91slug_93Ku2wEZ28zzMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/book/[slug].vue"),
  genI18nRoute("/book/:slug()"),
  genI18nName("book-slug"),
  _91slug_932nzjUIpa5QMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/categories/books.vue"),
  genI18nRoute("/categories/books"),
  genI18nName("categories-books"),
  booksoKUYqkCBu9Meta
],
[
  () => import("/builds/nextory/web/nx-web/pages/categories/magazines.vue"),
  genI18nRoute("/categories/magazines"),
  genI18nName("categories-magazines"),
  magazinesz3Zn7ntxnVMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/category/books/[slug].vue"),
  genI18nRoute("/category/books/:slug()"),
  genI18nName("category-books-slug"),
  _91slug_93oGJB6cAXioMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/category/index.vue"),
  genI18nRoute("/category"),
  genI18nName("category"),
  indexnFmb7boiCSMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/category/magazines/[slug].vue"),
  genI18nRoute("/category/magazines/:slug()"),
  genI18nName("category-magazines-slug"),
  _91slug_93Y0xARaMsNNMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/collab/[type]/[slug].vue"),
  genI18nRoute("/collab/:type()/:slug()"),
  genI18nName("collab-type-slug"),
  _91slug_93Lz7BXn14UoMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/collab/contact/[slug].vue"),
  genI18nRoute("/collab/contact/:slug()"),
  genI18nName("collab-contact-slug"),
  _91slug_93tcPJWqzaSsMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/content/[slug].vue"),
  genI18nRoute("/content/:slug()"),
  genI18nName("content-slug"),
  _91slug_93IGKbJJa7AqMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/download-apps.vue"),
  genI18nRoute("/download-apps"),
  genI18nName("download-apps"),
  download_45appsMVg2VtAgY5Meta
],
[
  () => import("/builds/nextory/web/nx-web/pages/e-books.vue"),
  genI18nRoute("/e-books"),
  genI18nName("e-books"),
  e_45booksJ2VoX65FsiMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/error.vue"),
  genI18nRoute("/error"),
  genI18nName("error"),
  errorz1q9oBZV92Meta
],
[
  () => import("/builds/nextory/web/nx-web/pages/for-kids.vue"),
  genI18nRoute("/for-kids"),
  genI18nName("for-kids"),
  for_45kidsKFDo2BtVhpMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/health-check.vue"),
  genI18nRoute("/health-check"),
  genI18nName("health-check"),
  health_45checkDFwoyjSBJkMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/imprint.vue"),
  genI18nRoute("/imprint"),
  genI18nName("imprint"),
  imprint3ET3BXRLRAMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/in-app/launch.vue"),
  ["/fr/in-app/launch", "/fr-en/in-app/launch", "/be/in-app/launch", "/be-en/in-app/launch"],
  ["in-app-launch___fr", "in-app-launch___fr-en", "in-app-launch___be", "in-app-launch___be-en"],
  launchSKIUJb4oT4Meta
],
[
  () => import("/builds/nextory/web/nx-web/pages/launch/nextory-app.vue"),
  genI18nRoute("/launch/nextory-app"),
  genI18nName("launch-nextory-app"),
  nextory_45appC3OqzSOFn9Meta
],
[
  () => import("/builds/nextory/web/nx-web/pages/magazines.vue"),
  genI18nRoute("/magazines"),
  genI18nName("magazines"),
  magazineshZvXPiABx6Meta
],
[
  () => import("/builds/nextory/web/nx-web/pages/migration/book/[id].vue"),
  genI18nRoute("/migration/book/:id()"),
  genI18nName("migration-book-id"),
  _91id_93eUne15OPTkMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/migration/category/[slug].vue"),
  genI18nRoute("/migration/category/:slug()"),
  genI18nName("migration-category-slug"),
  _91slug_93KFsGPwIuhTMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/narrator/[slug].vue"),
  genI18nRoute("/narrator/:slug()"),
  genI18nName("narrator-slug"),
  _91slug_93zyfmyR3jFDMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/privacy-policy.vue"),
  genI18nRoute("/privacy-policy"),
  genI18nName("privacy-policy"),
  privacy_45policyyVkw9BlJjHMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/profile/cancel/disclaimer.vue"),
  genI18nRoute("/profile/cancel/disclaimer"),
  genI18nName("profile-cancel-disclaimer"),
  disclaimergov7o876olMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/profile/cancel/downgrade-success.vue"),
  genI18nRoute("/profile/cancel/downgrade-success"),
  genI18nName("profile-cancel-downgrade-success"),
  downgrade_45successqCzDhRONNMMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/profile/cancel/extend-trial-success.vue"),
  genI18nRoute("/profile/cancel/extend-trial-success"),
  genI18nName("profile-cancel-extend-trial-success"),
  extend_45trial_45successR4tjMXPf5tMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/profile/cancel/offer.vue"),
  genI18nRoute("/profile/cancel/offer"),
  genI18nName("profile-cancel-offer"),
  offerwlUkSoVa0qMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/profile/cancel/reasons.vue"),
  genI18nRoute("/profile/cancel/reasons"),
  genI18nName("profile-cancel-reasons"),
  reasonsJrnIipy4LSMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/profile/cancel/revert-cancel.vue"),
  genI18nRoute("/profile/cancel/revert-cancel"),
  genI18nName("profile-cancel-revert-cancel"),
  revert_45canceleDbs0tLpt6Meta
],
[
  () => import("/builds/nextory/web/nx-web/pages/profile/cancel/stay-another-month-success.vue"),
  genI18nRoute("/profile/cancel/stay-another-month-success"),
  genI18nName("profile-cancel-stay-another-month-success"),
  stay_45another_45month_45successovk3vltEDWMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/profile/cancel/success.vue"),
  genI18nRoute("/profile/cancel/success"),
  genI18nName("profile-cancel-success"),
  successl36nLmp96nMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/profile/change-email.vue"),
  genI18nRoute("/profile/change-email"),
  genI18nName("profile-change-email"),
  change_45emailYYMH2WsmZOMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/profile/change-password.vue"),
  genI18nRoute("/profile/change-password"),
  genI18nName("profile-change-password"),
  change_45passwordDaQrFQuteQMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/profile/index.vue"),
  genI18nRoute("/profile"),
  genI18nName("profile"),
  indexC9R9enrEPXMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/profile/login-partner.vue"),
  genI18nRoute("/profile/login-partner"),
  genI18nName("profile-login-partner"),
  login_45partnero7glSHvBJJMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/profile/login.vue"),
  genI18nRoute("/profile/login"),
  genI18nName("profile-login"),
  loginMRPBdckEnwMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/profile/order-history.vue"),
  genI18nRoute("/profile/order-history"),
  genI18nName("profile-order-history"),
  order_45historyTbLoZx8QkaMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/profile/profile.vue"),
  genI18nRoute("/profile/profile"),
  genI18nName("profile-profile"),
  profileJDAo1KQArEMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/profile/providers/free.vue"),
  genI18nRoute("/profile/providers/free"),
  genI18nName("profile-providers-free"),
  freed3gVMUq31pMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/profile/providers/orange-tn.vue"),
  genI18nRoute("/profile/providers/orange-tn"),
  genI18nName("profile-providers-orange-tn"),
  orange_45tntYMqi9RP3aMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/profile/providers/orange.vue"),
  genI18nRoute("/profile/providers/orange"),
  genI18nName("profile-providers-orange"),
  orangenHerSLotNyMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/profile/quick-login.vue"),
  genI18nRoute("/profile/quick-login"),
  genI18nName("profile-quick-login"),
  quick_45loginEsUWiediYEMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/profile/read-and-listen-again.vue"),
  genI18nRoute("/profile/read-and-listen-again"),
  genI18nName("profile-read-and-listen-again"),
  read_45and_45listen_45againYaY3SmgNUEMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/profile/receipt.vue"),
  genI18nRoute("/profile/receipt"),
  genI18nName("profile-receipt"),
  receipt6cBpO9E7geMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/profile/registration.vue"),
  genI18nRoute("/profile/registration"),
  genI18nName("profile-registration"),
  registrationiNgHk5tf6MMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/profile/reset-password/index.vue"),
  genI18nRoute("/profile/reset-password"),
  genI18nName("profile-reset-password"),
  index3QLciIMuuWMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/profile/reset-password/success.vue"),
  genI18nRoute("/profile/reset-password/success"),
  genI18nName("profile-reset-password-success"),
  success718W2iXoMZMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/register/back-to-app.vue"),
  genI18nRoute("/register/back-to-app"),
  genI18nName("register-back-to-app"),
  back_45to_45appjwOVShiNQPMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/register/campaign/[slug].vue"),
  genI18nRoute("/register/campaign/:slug()"),
  genI18nName("register-campaign-slug"),
  _91slug_93m6PSmlgiyEMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/register/campaign/activate.vue"),
  genI18nRoute("/register/campaign/activate"),
  genI18nName("register-campaign-activate"),
  activateaoKt9Ry0I6Meta
],
[
  () => import("/builds/nextory/web/nx-web/pages/register/campaign/validate.vue"),
  genI18nRoute("/register/campaign/validate"),
  genI18nName("register-campaign-validate"),
  validate8oL0ldoaFgMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/register/change-payment-success.vue"),
  genI18nRoute("/register/change-payment-success"),
  genI18nName("register-change-payment-success"),
  change_45payment_45success00A2RAlV7AMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/register/change-success.vue"),
  genI18nRoute("/register/change-success"),
  genI18nName("register-change-success"),
  change_45successhAWvVLoTrrMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/register/dimoco/challenge.vue"),
  genI18nRoute("/register/dimoco/challenge"),
  genI18nName("register-dimoco-challenge"),
  challengeNMSnvC27EYMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/register/dimoco/index.vue"),
  genI18nRoute("/register/dimoco"),
  genI18nName("register-dimoco"),
  indexz4OV1hiFNwMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/register/get-started.vue"),
  genI18nRoute("/register/get-started"),
  genI18nName("register-get-started"),
  get_45startedGjWzEuTwCHMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/register/giftcard/activate.vue"),
  genI18nRoute("/register/giftcard/activate"),
  genI18nName("register-giftcard-activate"),
  activateEUdXAd0ylrMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/register/giftcard/info.vue"),
  genI18nRoute("/register/giftcard/info"),
  genI18nName("register-giftcard-info"),
  infoaim6VTqDxtMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/register/giftcard/success.vue"),
  genI18nRoute("/register/giftcard/success"),
  genI18nName("register-giftcard-success"),
  successguBUA10PYoMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/register/ideal.vue"),
  genI18nRoute("/register/ideal"),
  genI18nName("register-ideal"),
  ideal4Vz2uCsdnwMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/register/index.vue"),
  genI18nRoute("/register"),
  genI18nName("register"),
  indexp9otkVab4UMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/register/partner/[channel]/index.vue"),
  genI18nRoute("/register/partner/:channel()"),
  genI18nName("register-partner-channel"),
  indexyGu9z3LRTvMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/register/partner/[channel]/link.vue"),
  genI18nRoute("/register/partner/:channel()/link"),
  genI18nName("register-partner-channel-link"),
  link3d8OGI3nIwMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/register/partner/thank-you.vue"),
  genI18nRoute("/register/partner/thank-you"),
  genI18nName("register-partner-thank-you"),
  thank_45youWMAoyyDIZ4Meta
],
[
  () => import("/builds/nextory/web/nx-web/pages/register/payment-mode.vue"),
  genI18nRoute("/register/payment-mode"),
  genI18nName("register-payment-mode"),
  payment_45modeLmQkHb0ioMMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/register/payment-success.vue"),
  genI18nRoute("/register/payment-success"),
  genI18nName("register-payment-success"),
  payment_45successwTYx87IGOsMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/register/paypal.vue"),
  genI18nRoute("/register/paypal"),
  genI18nName("register-paypal"),
  paypaldk1oXXj7GeMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/register/planform.vue"),
  genI18nRoute("/register/planform"),
  genI18nName("register-planform"),
  planformgauQ43SDhBMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/register/planinfo.vue"),
  genI18nRoute("/register/planinfo"),
  genI18nName("register-planinfo"),
  planinfo6G7vtz9vMaMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/register/sofort.vue"),
  genI18nRoute("/register/sofort"),
  genI18nName("register-sofort"),
  sofort6UNv22Sd16Meta
],
[
  () => import("/builds/nextory/web/nx-web/pages/register/subscription-picker.vue"),
  genI18nRoute("/register/subscription-picker"),
  genI18nName("register-subscription-picker"),
  subscription_45pickerGtsn4dDqsWMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/register/trustly.vue"),
  genI18nRoute("/register/trustly"),
  genI18nName("register-trustly"),
  trustlyHU2tHpkS5AMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/register/welcome.vue"),
  genI18nRoute("/register/welcome"),
  genI18nName("register-welcome"),
  welcome8kx1qbgZkRMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/search.vue"),
  genI18nRoute("/search"),
  genI18nName("search"),
  searchsRRuYhowl1Meta
],
[
  () => import("/builds/nextory/web/nx-web/pages/series/[slug].vue"),
  genI18nRoute("/series/:slug()"),
  genI18nName("series-slug"),
  _91slug_93qtaHeRYL34Meta
],
[
  () => import("/builds/nextory/web/nx-web/pages/subscriptions_faq/consumed-hours.vue"),
  genI18nRoute("/subscriptions_faq/consumed-hours"),
  genI18nName("subscriptions_faq-consumed-hours"),
  consumed_45hoursK4LzDENdyyMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/subscriptions_faq/logged-out.vue"),
  genI18nRoute("/subscriptions_faq/logged-out"),
  genI18nName("subscriptions_faq-logged-out"),
  logged_45outuZwVoNr04eMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/test/ab-test-sample.vue"),
  genI18nRoute("/test/ab-test-sample"),
  genI18nName("test-ab-test-sample"),
  ab_45test_45sample2wAEUy44HWMeta
],
[
  () => import("/builds/nextory/web/nx-web/pages/user-terms.vue"),
  genI18nRoute("/user-terms"),
  genI18nName("user-terms"),
  user_45termsa81g33M8rRMeta
],
[
  () => import("/builds/nextory/web/nx-web/node_modules/nuxt/dist/pages/runtime/component-stub.js"),
  ["/sitemap.xml", "/index-sitemap.xml", "/sv-SE-sitemap.xml", "/en-SE-sitemap.xml", "/fr-FR-sitemap.xml", "/en-FR-sitemap.xml", "/fr-BE-sitemap.xml", "/en-BE-sitemap.xml", "/nl-NL-sitemap.xml", "/en-NL-sitemap.xml", "/nb-NO-sitemap.xml", "/en-NO-sitemap.xml", "/es-ES-sitemap.xml", "/en-ES-sitemap.xml", "/da-DK-sitemap.xml", "/en-DK-sitemap.xml", "/fi-FI-sitemap.xml", "/en-FI-sitemap.xml", "/de-CH-sitemap.xml", "/en-CH-sitemap.xml", "/de-AT-sitemap.xml", "/en-AT-sitemap.xml", "/de-DE-sitemap.xml", "/en-DE-sitemap.xml"],
  component_45stubyaJx2e3ivpMeta
]
];
export default packedRoutes.flatMap(([component, paths, names, meta, aliases, redirects]) =>
  paths.map((path, index) => ({
    path,
    component,
    ...(names ? { name: names[index] } : {}),
    meta: meta ? (Array.isArray(meta) ? meta[index] : meta) : {},
    ...(aliases ? { alias: aliases[index] } : {}),
    ...(redirects ? { redirect: redirects[index] } : {}),
  }))
);