export function useCustomHead() {
  const { $i18n, $link, $localePath, $store } = useNuxtApp()

  const i18nHead = useLocaleHead({
    addSeoAttributes: {
      canonicalQueries: [
        'q', // Search page
        'page', // Catalog pagination
        'preview', // Allows us to preview Echo CMS drafts
        'futureHero', // Special parameters for A/B testing the hero banner on homepage
      ],
    },
    addDirAttribute: true,
  })

  const title = $i18n.t('meta.default.title')
  const description = $i18n.t('meta.default.description', {
    trialDays: $store.state.webConfig.config.trialDays,
  })
  const meta = metaElements(title, description)

  useHead({
    title: meta.title,
    htmlAttrs: { ...i18nHead.value?.htmlAttrs },
    meta: [
      ...(i18nHead.value?.meta || []),
      ...meta.meta,
      {
        // Pinterest Domain Verification
        name: 'p:domain_verify',
        content: '5e47a3f3468a504ced3d7cf678d28c10',
      },
      { name: 'twitter:card', content: 'app' },
      {
        name: 'twitter:app:country',
        content: $i18n.localeProperties.value.language,
      },
      { name: 'theme-color', content: '#f6495b' },
      { name: 'msapplication-TileColor', content: '#f6495b' },
      {
        property: 'og:url',
        content: process.env.NEXTORY_WEB_URL,
        hid: 'ogUrl',
      },
      {
        property: 'og:image:secure_url',
        content: `${process.env.NEXTORY_WEB_URL}logo-nextory.png`,
      },
      {
        name: 'twitter:site',
        content: process.env.NEXTORY_WEB_URL,
        hid: 'tUrl',
      },
      {
        name: 'apple-itunes-app',
        content: 'app-id=993578896',
      },
      { name: 'twitter:app:name:iphone', content: 'Nextory' },
      { name: 'twitter:app:name:ipad', content: 'Nextory' },
      { name: 'twitter:app:id:iphone', content: 993578896 },
      { name: 'twitter:app:id:ipad', content: 993578896 },
      {
        name: 'twitter:app:url:iphone',
        content: $link.downloadAppleStore,
      },
      {
        name: 'twitter:app:url:ipad',
        content: $link.downloadAppleStore,
      },
      { name: 'twitter:app:name:googleplay', content: 'Nextory' },
      { name: 'twitter:app:id:googleplay', content: 'com.gtl.nextory' },
      {
        name: 'twitter:app:url:googleplay',
        content: $link.downloadGooglePlayStore,
      },
    ],
    link: [
      ...(i18nHead.value?.link || []),
      {
        rel: 'apple-touch-icon',
        sizes: '180x180',
        href: '/favicons/favicon-180.png',
      },
      {
        rel: 'icon',
        type: 'image/png',
        sizes: '96x96',
        href: '/favicons/favicon-96.png',
      },
      {
        rel: 'icon',
        type: 'image/png',
        sizes: '32x32',
        href: '/favicons/favicon-32.png',
      },
      {
        rel: 'icon',
        type: 'image/png',
        sizes: '16x16',
        href: '/favicons/favicon-16.png',
      },
      { rel: 'manifest', href: '/site.webmanifest' },
      { rel: 'mask-icon', href: '/favicons/favicon.svg', color: '#f6495b' },
      { rel: 'icon', type: 'image/x-icon', href: '/favicons/favicon.ico' },
    ],
  })

  useJsonld([
    {
      '@context': 'https://schema.org',
      '@type': 'WebApplication',
      name: $i18n.t('meta.default.title'),
      description: $i18n.t('meta.default.description', {
        trialDays: $store.state.webConfig.config.trialDays,
      }),
      url: $link.absolute(
        $localePath({
          name: 'categories-books',
        })
      ),
      operatingSystem: 'Any platform with a web browser',
      offers: getStructuredOffer(),
      applicationCategory: 'Books & Reference',
      contentRating: 'PEGI 12',
      aggregateRating: {
        '@id': 'urn:nextory:web:rating',
        '@type': 'AggregateRating',
        ratingValue: 4.3,
        reviewCount: 511,
      },
      image:
        'https://assets.nextory.com/web/SoftwareApplication/software_application.png',
      author: getNextoryOrganization(),
    },
  ])
}
