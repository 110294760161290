import { type AxiosResponse, type AxiosResponseHeaders } from 'axios'
import type { LogLevel } from 'consola'
import consola from 'consola'
import type { RequestInterface } from './interfaces/request'
// @ts-ignore
import { version } from '~/package'

const logger = consola.withTag('Hummingbird (Nextory)')

class AxiosLikeError extends Error {
  constructor(
    message: string,
    public code: string,
    public config: any,
    public request: any,
    public response: any
  ) {
    super(message)
  }
}

export type HttpRequest = {
  baseURL?: string
  data?: object | string
  headers?: Record<string, string | undefined>
  method: 'get' | 'post' | 'put' | 'delete'
  params?: Record<string, string | number>
  query?: Record<string, string | number>
  url: string
}

export class Request implements RequestInterface {
  public readonly headers: Record<string, string>
  private logLevel: LogLevel

  constructor(
    private origin: string, // eg. https://staging.nextory.app
    private baseURL: string,
    private countryCode?: string, // Format: "SE"
    private locale?: string, // Format: "sv"
    sandboxHeaders: Record<string, string> = {}
  ) {
    this.logLevel = /* info (number) */ 3
    this.headers = this.prepareHeaders(sandboxHeaders)
  }

  /**
   * This function does the bridging between our old Axios requests and the new useFetch from Nuxt 3.
   */
  public http({
    baseURL,
    data,
    headers,
    method,
    params,
    query,
    url,
  }: HttpRequest): Promise<AxiosResponse> {
    const options = {
      method,
      query,
      body: data,
      headers: {
        ...this.headers,
        ...headers,
      },
      baseURL: baseURL || this.baseURL,
      timeout: 10 /* seconds */ * 1000, // In milliseconds
    }
    const urlWithParams = new URL(url, options.baseURL)

    if (params) {
      Object.keys(params).forEach(key => {
        if (params[key] !== undefined) {
          urlWithParams.searchParams.append(key, params[key])
        }
      })
    }

    // Only get /route?param=value part of the URL now (no origin, but both path and search)
    url = urlWithParams.pathname + urlWithParams.search

    return new Promise((resolve, reject) => {
      useFetch(
        url,
        {
          ...options,
          deep: false, // Disable reactivity
        } as any /* we can't 100% match useFetch types, but our implementation is close enough and even more precise, so `any` is fine */
      ).then(({ data, error }) => {
        if (error.value) {
          // Simulate an Axios error
          const statusCode = error.value?.statusCode || 500
          const errorBody = error.value?.data || 'Unknown error'
          const errorName = `Error fetching data from: ${url} - ${statusCode} ${JSON.stringify(errorBody)}`

          reject(
            new AxiosLikeError(errorName, 'ERR_BAD_RESPONSE', null, null, {
              data: errorBody,
              status: statusCode,
              statusText: errorName,
              headers: options.headers,
              config: {},
            })
          )

          return
        }

        // Transform it to AxiosResponse, to match the legacy code
        resolve({
          data: data.value,
          status: 200,
          statusText: 'OK',
          headers: options.headers as AxiosResponseHeaders,
          config: {},
        })
      })
    })
  }

  private prepareHeaders(sandboxHeaders: Record<string, string>) {
    const headers = {
      'Content-Type': 'application/json',
      'X-Application-Id': '203', // Web
      'X-Model': 'web',
      'X-App-Version': version,
      'X-Country-Code': this.countryCode,
      'X-Locale': `${this.locale}_${this.countryCode}`, // Format locale_COUNTRY, e.g. sv_SE
    } as Record<string, string>

    if (import.meta.server) {
      // Origin must be specified in SSR (but not in a browser - it'll add it itself)
      headers.Origin = this.origin
    }

    // Add sandbox headers
    Object.keys(sandboxHeaders).forEach(key => {
      headers[key] = sandboxHeaders[key]
    })

    return headers
  }

  private setHeader(header: string, value: string | null | undefined) {
    if (value) {
      this.headers[header] = value
    } else {
      delete this.headers[header]
    }
  }

  _setDeviceId(deviceId: string) {
    this.setHeader('X-Device-Id', deviceId)
  }

  _setCountry(country: string | undefined) {
    this.setHeader('X-Country-Code', country)
  }

  _setLogLevel(logLevel: LogLevel) {
    this.logLevel = logLevel
    logger.level = logLevel
  }

  _setAuthToken(authToken?: string) {
    this.setHeader('X-Login-Token', authToken)
  }
}
